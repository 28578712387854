<template>
  <div
    :class="classNameComputed"
    :data-test-id="dataTestId"
    :inert="disabled"
  >
    <ui-button
      :size="size"
      :color="color"
      data-test-id="stepper-counter-minus"
      label="-"
      :click-event="onDecrement"
      square
      :disabled="disabled || value === minimumValue"
    >
      <ui-icon
        name="remove"
        color="white"
        :size="24"
        disable-hover
      />
    </ui-button>
    <div class="v-typography-title-5 v-py-xs">
      <common-tweened-number :num="value" />
    </div>
    <ui-button
      :size="size"
      :color="color"
      data-test-id="stepper-counter-plus"
      label="+"
      :click-event="onIncrement"
      :disabled="disabled || disablePlusButton"
      square
    >
      <ui-icon
        disable-hover
        name="add"
        color="white"
        :size="24"
      />
    </ui-button>
  </div>
</template>

<script setup lang="ts">
import type { AutoTest, CanBeDisabled, VElementNonInteractive } from '@arora/common'

const {
  className,
  minimumValue = 0,
  size
} = defineProps<
  AutoTest &
    CanBeDisabled &
    VElementNonInteractive & {
      disablePlusButton?: boolean
      minimumValue?: number
      onIncrement: () => Promise<void>
      onDecrement: () => Promise<void>
      color: 'grey' | 'primary' | 'primary-opacity'
      size: 32 | 36 | 44
      value: number
    }
>()

const classNameComputed = computed<string>(() => {
  let result = `v-ui-stepper v-ui-stepper-size--${size}`

  if (className) {
    result += ` ${className}`
  }

  return result
})
</script>

<style lang="scss">
.v-ui-stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-size--32 {
    min-width: 7.855rem;
  }

  &-size--36 {
    min-width: 8.355rem;
  }

  &-size--44 {
    min-width: 9.5rem;
  }
}
</style>
